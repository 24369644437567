import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";

export default function BlogPost({ pageContext }) {
  const {
    post: {
      featured_image,
      thumbnail,
      title,
      subtitle,
      slug,
      read_time,
      content,
      attachements,
      author,
    },
  } = pageContext;

  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{title} | MCA</title>
        <meta name="title" content={`${title} | MCA`} />
        <meta name="description" content={subtitle} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mcaindia.in" />
        <meta property="og:title" content={`${title} | MCA`} />
        <meta property="og:description" content={subtitle} />
        <meta property="og:image" content={featured_image || thumbnail} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://mcaindia.in" />
        <meta property="twitter:title" content={`${title} | MCA`} />
        <meta property="twitter:description" content={subtitle} />
        <meta property="twitter:image" content={featured_image || thumbnail} />
      </Helmet>

      <Navbar />

      <div className="w-full sm:mb-20">
        <div className="w-full relative h-[50vh]">
          <div className="z-10 w-full h-full">
            <img src={featured_image} className="h-full w-full object-cover" />
          </div>
        </div>
      </div>

      <div className="max-w-[65ch] mx-auto mb-28 px-5 w-screen overflow-x-hidden">
        <div className="my-10 flex flex-col gap-y-2 sm:gap-y-5">
          <div className="text-4xl sm:text-6xl text-mca-grey font-extrabold">
            {title}
          </div>
          <div className="text-2xl sm:text-3xl text-mca-grey-2 font-semibold">
            {subtitle}
          </div>
          <div className="text-md sm:text-lg text-mca-grey-2">
            {read_time} min read
          </div>
          <div className="flex items-center gap-x-3 sm:gap-x-5 mt-3">
            <img
              className="h-14 w-14 sm:h-20 sm:w-20 object-cover rounded-full"
              src={author.avatar}
            />
            <div className="flex flex-col sm:gap-y-2">
              <div className="text-lg sm:text-xl text-mca-grey font-bold">
                {author.name}
              </div>
              <div className="sm:text-md text-mca-grey-2">
                {author.designation}
              </div>
            </div>
          </div>
        </div>

        <div
          className="blog-post-content prose sm:prose-lg mx-auto"
          dangerouslySetInnerHTML={{
            __html: content.replaceAll(`<p>&nbsp;</p>`, ""),
          }}
        ></div>
      </div>

      <Footer />
    </div>
  );
}
